import { ReactElement } from 'react'
import AssessmentTabList from '../AssessmentTabList/AssessmentTabList'
import AssessmentTab from '../AssessmentTab/AssessmentTab'
import { Grid } from '@mui/material'

type AssessmentWeb2Props = Readonly<{
    metadata: any,
    fullMetadataUri: string,
    fullReportUrl: string
}>




const AssessmentWeb2 = ({metadata, fullMetadataUri, fullReportUrl}: AssessmentWeb2Props): ReactElement => {

    return (
        <AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={metadata.risk} label={'Report Summary'}>
                <Grid className="field" item xs={4} md={4}>Report on:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.client}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report date:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.date}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Risk summary:</Grid>
                <Grid className="data" item xs={8} md={8}><span className="riskScore-sm twenty-bg"><span>{metadata.risk}</span></span></Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report number:</Grid>
                <Grid className="data" item xs={8} md={8}>{'This is the initial assessment'}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Detailed Metadata Storage:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullMetadataUri} target="_blank" rel="noreferrer">Permalink</a></Grid>
                <hr/>
            </AssessmentTab>



            <AssessmentTab score={metadata.risk} label={'Full Report'}>
                <Grid className="field" item xs={4} md={4}>Detailed Report Link:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullReportUrl} target="_blank" rel="noreferrer">Report link</a></Grid>

                <Grid className="field" item xs={12}><iframe src={fullReportUrl} width="100%" height="400px"></iframe></Grid>
            </AssessmentTab>
        </AssessmentTabList>
    )

}

export default AssessmentWeb2