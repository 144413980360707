import { ReactElement, ReactNode } from 'react'
import AssessmentTabList from '../AssessmentTabList/AssessmentTabList'
import AssessmentTab from '../AssessmentTab/AssessmentTab'
import { Grid } from '@mui/material'

type AssessmentGithubProps = Readonly<{
    metadata: any,
    fullMetadataUri: string,
    fullReportUrl: string
}>

const renderSolcVersionVulnerability = (tRisk: any): ReactElement => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Solc Version Used:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.version}</Grid>
    </>)
}

const renderMissingImmutableVulnerability = (tRisk: any): ReactElement => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Variable Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variable}</Grid>
    </>)
}

const renderReentrancyVulnerability = (tRisk: any): ReactElement => {
    return(<>

        <Grid className="field" item xs={4} md={4}>Function Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.function}</Grid>

        <Grid className="field" item xs={4} md={4}>External Contract called:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contractCall}</Grid>
    </>)
}

const renderCentralizationRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Centralized Roles:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.roles.toString()}</Grid>
    </>)
}

const renderOneStepOwnershipTransferRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>
    </>)
}

const renderRaceConditionContractRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Contracts address modifiers:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.modifiers.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Vulnerable functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Affected variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderReentrancyGuardCheckEffectsRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variable modifiers:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.modifiers.toString()}</Grid>
    </>)
}

const renderRaceConditionRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>
        {tRisk.modifiers && tRisk.modifiers.length > 0 ?
            <><Grid className="field" item xs={4} md={4}>Variable modifiers:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.modifiers.toString()}</Grid>
            </> : null}
        {tRisk.functions && tRisk.functions.length > 0 ?
            <><Grid className="field" item xs={4} md={4}>Functions:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>
            </> : null}

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderUnboundedLookRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>
    </>)
}

const renderNonEoaContractInjectionTxOriginRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>
    </>)
}

const renderMissingTimeValidationRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderMissingApprovalsCheckRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderMissingValidationsRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderMissingLowerBoundValidationsRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderMissingERC20ValidationsRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Variables:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variables.toString()}</Grid>
    </>)
}

const renderRoundingErrorsIntegerDivisionRisk = (tRisk: any): ReactElement => {
    return(<>
        <Grid className="field" item xs={4} md={4}>Contracts Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contracts.toString()}</Grid>

        <Grid className="field" item xs={4} md={4}>Functions:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.functions.toString()}</Grid>
    </>)
}


const vulnerabilityNameLookup = (t: string): string => {
    switch(t) {
    case 'reentrancy':
        return "Reentrancy"
    case 'missingImmutable':
        return 'Unnecessary Mutability'
    case 'solc_version':
        return 'Outdated or vulnerable compiler version'
    case 'centralization':
        return 'Centralization'
    case 'one_step_ownership_transfer':
        return 'One step Ownership Transfer'
    case 'race_condition_contract':
        return 'Called contract setter based race condition'
    case 'reentrancy_guard_check_effects':
        return 'Missing reentrancy guard, mitigated by check effects pattern'
    case 'race_condition':
        return 'Race Condition or transaction ordering dependence'
    case 'unbounded_loop':
        return 'Iteration over an unbounded loop and potential for block gas limit to be reached'
    case 'non_eoa_contract_injection_tx_origin':
        return 'Reliance on tx.origin with potential for non EOA contract injection'
    case 'missing_time_validation':
        return 'Missing time validation'
    case 'missing_approvals_check':
        return 'Missing approvals check'
    case 'missing_validations':
        return 'Missing validations'
    case 'missing_lower_bound_validations':
        return 'Missing Lower Bound Validations'
    case 'missing_erc20_address_validation':
        return 'Missing Variable Validation for validity of ERC-20 contract'
    case 'rounding_errors_integer_division':
        return 'Possible rounding errors due to integer division'
    default:
        return 'Unclassified error'
    }

}

const renderVulnerabilities = (techRisk: any): ReactNode => {
    if (techRisk.length > 0) {
        return techRisk.map((tRisk: any, index: number) => {
            return(<>

                <Grid className="split" item xs={12} key={`${tRisk.type}-${index}`}>Vulnerability {index + 1}</Grid>
                
                <Grid className="field" item xs={4} md={4}>Summary:</Grid>
                <Grid className="data" item xs={8} md={8}>{vulnerabilityNameLookup(tRisk.type)}</Grid>

                <Grid className="field" item xs={4} md={4}>Impact:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.impact}</Grid>

                {tRisk.type === 'reentrancy' ? renderReentrancyVulnerability(tRisk): ''}
                {tRisk.type === 'missingImmutable' ? renderMissingImmutableVulnerability(tRisk) : ''}
                {tRisk.type === 'solc_version' ? renderSolcVersionVulnerability(tRisk) : ''}
                {tRisk.type === 'centralization' ? renderCentralizationRisk(tRisk): ''}
                {tRisk.type === 'one_step_ownership_transfer' ? renderOneStepOwnershipTransferRisk(tRisk): ''}
                {tRisk.type === 'race_condition_contract' ? renderRaceConditionContractRisk(tRisk): ''}
                {tRisk.type === 'reentrancy_guard_check_effects' ? renderReentrancyGuardCheckEffectsRisk(tRisk): ''}
                {tRisk.type === 'race_condition' ? renderRaceConditionRisk(tRisk): ''}
                {tRisk.type === 'unbounded_loop' ? renderUnboundedLookRisk(tRisk): ''}
                {tRisk.type === 'non_eoa_contract_injection_tx_origin' ? renderNonEoaContractInjectionTxOriginRisk(tRisk): ''}
                {tRisk.type === 'missing_time_validation' ? renderMissingTimeValidationRisk(tRisk): ''}
                {tRisk.type === 'missing_approvals_check' ? renderMissingApprovalsCheckRisk(tRisk): ''}
                {tRisk.type === 'missing_validations' ? renderMissingValidationsRisk(tRisk): ''}
                {tRisk.type === 'missing_lower_bound_validations' ? renderMissingLowerBoundValidationsRisk(tRisk): ''}
                {tRisk.type === 'missing_erc20_address_validation' ? renderMissingERC20ValidationsRisk(tRisk): ''}
                {tRisk.type === 'rounding_errors_integer_division' ? renderRoundingErrorsIntegerDivisionRisk(tRisk): ''}
            </>)
        })
    } else {
        return null
    }
}

const AssessmentGithub = ({metadata, fullMetadataUri, fullReportUrl}: AssessmentGithubProps): ReactElement => {

    return (
        <AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={metadata.risk} label={'Report Summary'}>
                <Grid className="contentBox" item xs={12}>
                    <Grid container>

                        <Grid className="field" item xs={2}>Report on:</Grid>
                        <Grid className="data" item xs={5}>{metadata.client}</Grid>

                        <Grid className="field" item xs={2}>Report number:</Grid>
                        <Grid className="data" item xs={3}>{'Initial assessment'}</Grid>

                        <Grid className="field" item xs={2}>Report hash:</Grid>
                        <Grid className="data" item xs={5}>{metadata.commitHash}</Grid>

                        <Grid className="field" item xs={2}>Risk summary:</Grid>
                        <Grid className="data" item xs={3}><span className="riskScore-sm twenty-bg"><span>{metadata.risk}</span></span></Grid>

                        <Grid className="field" item xs={2}>Repository:</Grid>
                        <Grid className="data" item xs={5}>{metadata.repo}</Grid>

                        <Grid className="field" item xs={2}>Storage link:</Grid>
                        <Grid className="data" item xs={3}><a href={fullMetadataUri} target="_blank" rel="noreferrer">Permalink</a></Grid>

                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.team.risk} label={'Team Risk'}>

                <Grid className="header" item xs={12} sm={3} lg={2}>Team Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        <Grid className="field" item xs={4} lg={3}>Team doxxing status: </Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.team['doxxed-public'] ? 'public' : (metadata.risks.team['doxxed'] ? 'private': 'anonymous')}</Grid>
                        <Grid className="field" item xs={4} lg={3}>Team experience:</Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.team.experience}</Grid>
                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.technicalRisk.risk} label={'Contract Risk'}>

                <Grid className="header" item xs={12} sm={3} lg={2}>Contract Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        {renderVulnerabilities(metadata.risks.technicalRisk.vulnerabilities)}
                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risk} label={'Full Report'}>
                <Grid className="field" item xs={4} md={4}>Detailed Report Link:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullReportUrl} target="_blank" rel="noreferrer">Report link</a></Grid>

                <Grid className="field" item xs={12}><iframe src={fullReportUrl} width="100%" height="400px"></iframe></Grid>
            </AssessmentTab>
        </AssessmentTabList>
    )

}

export default AssessmentGithub