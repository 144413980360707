import '../../App.scss';
import { ethers } from 'ethers';
import ky from 'ky';
import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import {useState, useEffect, JSX, ReactNode} from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Provider, types } from "zksync-ethers"
import AssessmentTab from '../../components/AssessmentTab/AssessmentTab';
import AssessmentTabList from '../../components/AssessmentTabList/AssessmentTabList';
import AssessmentVersionOne from '../../components/AssessmentVersionOne/AssessmentVersionOne';
import AssessmentWeb2 from '../../components/AssessmentWeb2/AssessmentWeb2';
import AssessmentGithub from '../../components/AssessmentGithub/AssessmentGithub';


const selectRiskClass = (score: number | 'N/A') => {
    if (score === 'N/A') {
        return 'na'
    } else if (score <= 10) {
        return 'ten'
    } else if (score <= 20) {
        return 'twenty'
    } else if (score <= 30) {
        return 'thirty'
    } else if (score <= 40) {
        return 'forty'
    } else if (score <= 50) {
        return 'fifty'
    } else if (score <= 60) {
        return 'sixty'
    } else if (score <= 70) {
        return 'seventy'
    } else if (score <= 80) {
        return 'eighty'
    } else {
        return 'ninety'
    }
}

const abi = ["function tokenURI(uint256) public view returns (string)"]

const contractAddress = '0x56E3cB1C9C8ae6Ca71578713fde0d37b799dC589'


const renderAmlWalletChains = (walletChains: Record<string, any>): ReactNode => {
    if (Object.keys(walletChains).length > 0) {
        return Object.keys(walletChains).map(key => {
            return(<>
                <Grid className="field" item xs={4} lg={3}>Transaction id:</Grid>
                <Grid className="data contract" item xs={8} lg={9}>{key}</Grid>

                <Grid className="field" item xs={4} lg={3}>Age: </Grid>
                <Grid className="data" item xs={8} lg={9}>{walletChains[key].age}</Grid>

                <Grid className="field" item xs={4} lg={3}>Amount:</Grid>
                <Grid className="data" item xs={8} lg={9}>{walletChains[key].amount} ETH</Grid>

                <Grid className="field" item xs={4} lg={3}>Source:</Grid>
                <Grid className="data" item xs={8} lg={9}>{walletChains[key].exchangeTag}</Grid>

                <Grid className="field" item xs={4} lg={3}>Source issue:</Grid>
                <Grid className="data" item xs={8} lg={9}>{walletChains[key].exchangeIssue}</Grid>

                <Grid className="field" item xs={4} lg={3}>Impact:</Grid>
                <Grid className="data" item xs={8} lg={9}>{walletChains[key].impact}</Grid>

                <Grid className="field" item xs={4} lg={3}>Relevance chain:</Grid>
                <Grid className="data contract" item xs={8} lg={9}>{walletChains[key].walletChain}</Grid>
            </>)
        })
    } else {
        return null
    }
}

const renderWhaleFundingList = (fundingList: Record<string, ReadonlyArray<string>>, key: string): ReactNode => {
    if (fundingList) {
        const fundingDisplay = Object.keys(fundingList).map((funder, index) => {
            return (<>
                <Grid className="field" item xs={4} lg={3}>Funding source:</Grid>
                <Grid className="data contract" item xs={8} lg={9} key={key + '-whale-funder'}>{funder}</Grid>
                <Grid className="field inner" item xs={4} lg={3}>Transaction(s):</Grid>
                <Grid className="data contract" item xs={8} lg={9}>
                    {fundingList[funder].map((tx) => {
                        return(<Box key={key + '-whale-funder-tx-' + tx}>{tx}</Box>)
                    })}
                </Grid>
                {(index === Object.keys(fundingList).length -1) ? <></> : <hr className="sub"/>}
            </>)
        })
        return fundingDisplay
    } else {
        return <></>
    }
}

const renderWhaleSybilRisks = (sybilled: any, key: string): ReactNode => {
    if (sybilled && !Array.isArray(sybilled)) {
        const sybilKeys = Object.keys(sybilled)
        const sybilGrid = sybilKeys.map((sybilTx) => {
            return (<>
                <Grid className="field" item xs={4} lg={3}>Whale is sybilled with other whales:</Grid>
                <Grid className="data" item xs={8} lg={9}></Grid>
                <Grid className="field inner" item xs={4} lg={3}>Transaction(s): </Grid>
                <Grid className="data contract" item xs={8} lg={9}>
                    {sybilTx}
                </Grid>
                <Grid className="field inner" item xs={4} lg={3}>Whales: </Grid>
                <Grid item className="data contract" xs={8} lg={9}>
                    {
                        sybilled[sybilTx].map((sybil: any) => {
                            return <span key={`${key}-sybilled-whale-${sybil}`}> {sybil}</span>
                        })}
                </Grid>
            </>)
        })
        return sybilGrid
    } else if (sybilled && Array.isArray(sybilled) && sybilled.length > 0) {
        return (<><Grid className="field" item xs={4} lg={3}>Whale is sybilled with other whales:</Grid><Grid className="data" item xs={8} lg={9}>{(sybilled.map((sybil: any) => {
            return <span key={`${key}-sybilled-whale-${sybil}`}> {sybil}</span>
        }))
        }</Grid><hr/></>)
    } else {
        return <></>
    }
}

const renderWhaleRisks = (whaleRisks: any): ReactNode => {
    if (Object.keys(whaleRisks).length > 0) {
        return Object.keys(whaleRisks).map((key, index) => {
            return(<>

                <Grid className="split" item xs={12}>Whale {index +1}</Grid>
                
                <Grid className="field" item xs={4} lg={3}>Whale address:</Grid>
                <Grid className="data contract" item xs={8} lg={9}>{key}</Grid>

                <Grid className="field" item xs={4} lg={3}>Contract:</Grid>
                <Grid className="data" item xs={8} lg={9}>{(!!whaleRisks[key].contract).toString()}</Grid>

                {whaleRisks[key].contract ? <><Grid className="field" item xs={4} lg={3}>Deployed by:</Grid>
                    <Grid className="data contract" item xs={8} lg={9}>{whaleRisks[key].deployer}</Grid></> : ''}        

                {renderWhaleFundingList(whaleRisks[key].funding, key)}

                <Grid className="field" item xs={4} lg={3}>Proxy contract:</Grid>
                <Grid className="data" item xs={8} lg={9}>{(!!whaleRisks[key].proxy).toString()}</Grid>

                <Grid className="field" item xs={4} lg={3}>Holding amount:</Grid> 
                <Grid className="data" item xs={8} lg={9}>{(whaleRisks[key].supply === 0) ? 'Less than 0.5%' : `${whaleRisks[key].supply*100}%`}</Grid>

                {renderWhaleSybilRisks(whaleRisks[key].sybilled, key)}


                <Grid className="field" item xs={4} lg={3}>Verified contract:</Grid>
                <Grid className="data" item xs={8} lg={9}>{(!!whaleRisks[key].unverified).toString()}</Grid>
            </>)
        })
    } else {
        return null
    }

}

const renderSolcVersionVulnerability = (tRisk: any): JSX.Element => {
    return (<>
        <Grid className="field" item xs={4} lg={3}>Solc Version Used:</Grid>
        <Grid className="data" item xs={8} lg={9}>{tRisk.version}</Grid>
    </>)
}

const renderMissingImmutableVulnerability = (tRisk: any): JSX.Element => {
    return (<>
        <Grid className="field" item xs={4} lg={3}>Variable Affected:</Grid>
        <Grid className="data" item xs={8} lg={9}>{tRisk.variable}</Grid>
    </>)
}

const renderReentrancyVulnerability = (tRisk: any): JSX.Element => {
    return(<>

        <Grid className="field" item xs={4} lg={3}>Function Affected:</Grid>
        <Grid className="data" item xs={8} lg={9}>{tRisk.function}</Grid>

        <Grid className="field" item xs={4} lg={3}>External Contract called:</Grid>
        <Grid className="data" item xs={8} lg={9}>{tRisk.contractCall}</Grid>
    </>)
}

const renderVulnerabilities = (techRisk: any): ReactNode => {
    if (techRisk.length > 0) {
        return techRisk.map((tRisk: any, index: number) => {
            return(<>

                <Grid className="split" item xs={12}>Vulnerability {index + 1}</Grid>
                
                <Grid className="field" item xs={4} lg={3}>Vulnerability Summary:</Grid>
                <Grid className="data" item xs={8} lg={9}>{tRisk.type}</Grid>

                <Grid className="field" item xs={4} lg={3}>Vulnerability impact:</Grid>
                <Grid className="data" item xs={8} lg={9}>{tRisk.impact}</Grid>

                {tRisk.type === 'reentrancy' ? renderReentrancyVulnerability(tRisk): ''}
                {tRisk.type === 'missingImmutable' ? renderMissingImmutableVulnerability(tRisk) : ''}
                {tRisk.type === 'solc_version' ? renderSolcVersionVulnerability(tRisk) : ''}
            </>)
        })
    } else {
        return null
    }
}

const renderReport = (metadata: any, index: number): JSX.Element => {
    const fullMetadataUri = `https://ipfs.io/ipfs/${metadata.ownUrl}`
    const fullReportUrl = `https://ipfs.io/ipfs/${metadata.report}`
    if (metadata.version === undefined) {
        return(<AssessmentVersionOne
            metadata={metadata} 
            fullMetadataUri={fullMetadataUri}
            fullReportUrl={fullReportUrl}
        ></AssessmentVersionOne>)
    } else if (metadata.web2 === true) {
        return(<AssessmentWeb2 
            metadata={metadata}
            fullMetadataUri={fullMetadataUri}
            fullReportUrl={fullReportUrl}></AssessmentWeb2>
        )
    } else if (metadata.github === true) {
        return(<AssessmentGithub 
            metadata={metadata}
            fullMetadataUri={fullMetadataUri}
            fullReportUrl={fullReportUrl}></AssessmentGithub>
        )
    } else {
        return(<AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={metadata.risk} label={'Report Summary'}>
                <Grid className="contentBox" item xs={12}>
                    <Grid container>
                        <Grid className="field" item xs={2}>Report chain:</Grid>
                        <Grid className="data" item xs={5}>Ethereum Mainnet</Grid>

                        <Grid className="field" item xs={2}>Report block:</Grid>
                        <Grid className="data" item xs={3}>{metadata.block}</Grid>

                        <Grid className="field" item xs={2}>Contract:</Grid>
                        <Grid className="data contract" item xs={5}>{metadata.contract}</Grid>

                        <Grid className="field" item xs={2}>Report number:</Grid>
                        <Grid className="data" item xs={3}>{`${index}`}</Grid>

                        <Grid className="field" item xs={2}>Report on:</Grid>
                        <Grid className="data" item xs={5}>{metadata.client}</Grid>

                        <Grid className="field" item xs={2}>Risk summary:</Grid>
                        <Grid className="data" item xs={3}><span className="riskScore-sm twenty-bg"><span>{metadata.risk}</span></span></Grid>

                        <Grid className="field" item xs={2}>Storage Link:</Grid>
                        <Grid className="data" item xs={5}><a href={fullMetadataUri} target="_blank" rel="noreferrer">Permalink</a></Grid>

                        <Grid className="field" item xs={2}>Assessment is:</Grid>
                        <Grid className="data" item xs={3}>{metadata.prelaunch ? 'prelaunch' : 'post launch'}</Grid>
                    </Grid>
                </Grid>
            </AssessmentTab>


            <AssessmentTab score={metadata.risks.technicalRisk.risk} label={'Contract Risk'}>
                <Grid className="header" item xs={12} sm={3} lg={2}>Contract Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        <Grid className="field" item xs={4} lg={3}>Contract is a Honeypot:</Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.technicalRisk.honeyPot.toString()}</Grid>
                        <Grid className="field" item xs={4} lg={3}>Contract has tax:</Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.technicalRisk.tax.toString()}</Grid>
                        {renderVulnerabilities(metadata.risks.technicalRisk.vulnerabilities)}
                    </Grid>
                </Grid>
            </AssessmentTab>
            <AssessmentTab score={metadata.risks.liquidity.prelaunch ? 'N/A' : metadata.risks.team.risk} label={'Risk'}>
                <Grid className="header" item xs={12} sm={3} lg={2}>AML Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        {renderAmlWalletChains(metadata.risks.aml.walletChains)}
                    </Grid>
                </Grid>

                <Grid className="spacer" item xs={12}></Grid>

                <Grid className="header" item xs={12} sm={3} lg={2}>Team Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        <Grid className="field" item xs={4} lg={3}>Team doxxing status: </Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.team['doxxed-public'] ? 'public' : (metadata.risks.team['doxxed'] ? 'private': 'anonymous')}</Grid>
                        <Grid className="field" item xs={4} lg={3}>Team experience:</Grid>
                        <Grid className="data" item xs={8} lg={9}>{metadata.risks.team.experience}</Grid>
                    </Grid>
                </Grid>

                <Grid className="spacer" item xs={12}></Grid>

                <Grid className="header" item xs={12} sm={3} lg={2}>Whale Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        {metadata.risks.whales.prelaunch ? 'As this is a prelaunch assessment, whale analytics are of limited value' : ''}
                        {renderWhaleRisks(metadata.risks.whales.whales)}
                    </Grid>
                </Grid>

                <Grid className="spacer" item xs={12}></Grid>

                <Grid className="header" item xs={12} sm={3} lg={2}>Liquidity Risk</Grid>
                <Grid className="contentBox" item xs={12} sm={9} lg={10}>
                    <Grid container>
                        {metadata.risks.liquidity.prelaunch ? 'As this is a prelaunch assessment, liquidity analytics are not included' : ''}
                    </Grid>
                </Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risk} label={'Full Report'}>
                <Grid className="field" item xs={4} lg={3}>Detailed Report Link:</Grid>
                <Grid className="data" item xs={8} lg={9}><a href={fullReportUrl} target="_blank" rel="noreferrer">Report link</a></Grid>

                <Grid className="field" item xs={12}><iframe src={fullReportUrl} width="100%" height="400px"></iframe></Grid>
            </AssessmentTab>
        </AssessmentTabList>)
    }
}

const AssessmentScreen = (): JSX.Element => {

    const { assessmentId } = useParams()

    const navigate = useNavigate()
    const [metadata, setMetadata] = useState<any>(null);
    const [previousReports, setPreviousReports] = useState<ReadonlyArray<any>>([])
    const [previousReportFetchNecessary, setPreviousReportFetchNecessary] = useState<boolean>(false)
    const [selectedReport, setSelectedReport] = useState<number>(0)
    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const provider = Provider.getDefaultProvider(types.Network.Mainnet);
                const contract = new ethers.Contract(contractAddress, abi, provider)
                const mUri = await contract.tokenURI(assessmentId)
                const mData = await ky.get(`https://ipfs.io/ipfs/${mUri}`)
                const mDataBody = await mData.json()
                setMetadata({...(mDataBody as any), ownUrl: mUri})
                if ((mDataBody as any).previousReport) {
                    setPreviousReportFetchNecessary(true)
                }
            } catch (e) {
                navigate('/404')
            }

        }

        fetchHistory()
    }, [])

    useEffect(() => {
        const fetchPreviousReports = async () => {
            const rootMetadata = previousReports.length === 0 ? metadata : previousReports[previousReports.length -1]
            if (rootMetadata.previousReport !== null) {
                const mData = await ky.get(`https://ipfs.io/ipfs/${rootMetadata.previousReport}`)
                const mDataBody = await mData.json()
                const newMetadataHistory = [...previousReports, {...(mDataBody as any), ownUrl: rootMetadata.previousReport}]
                setPreviousReportFetchNecessary(false)
                setPreviousReports(newMetadataHistory)
                if ((mDataBody as any).previousReport !== null) {
                    setPreviousReportFetchNecessary(true)
                }
            } else {
                setPreviousReportFetchNecessary(false)
            }
        }

        if (previousReportFetchNecessary === true) {
            fetchPreviousReports()
        }
    }, [previousReportFetchNecessary, previousReports, metadata])

    const selectAudit = (index: number) => {
        setSelectedReport(index)
    }

    const renderPreviousReports = (allReports: ReadonlyArray<any>): ReactNode => {
        const previous = allReports.map((previousReport, index) => {
            const isSelectedReport = index === selectedReport
            const date = previousReport.date ? previousReport.date : (previousReport.version === 2) ? '03/07/2024' : '14/06/2024'
            return(<Grid item sm={12} md={12} className={'audit-selector ' + (isSelectedReport ? 'selected' : '')} key={`assessment-${index}`}>
                <div className={'auditBlock '+ (isSelectedReport ? 'selected' : '')} onClick={selectAudit.bind({}, index)}>
                    {previousReport.client}
                    <h4 className={`riskScore-sm ${selectRiskClass(previousReport.risk)}-bg`}><span>{previousReport.risk} / 100</span></h4>
                    <h3 className='auditDate'>{date}</h3>
                </div>
            </Grid>)

        })
    
        return(<>
            {previous}
        </>)
    }

    if(metadata === null) {
        return (<div></div>)
    } else {
        const allReports = [metadata, ...previousReports]

        return (
            <div className="wrapper">
                <AppBar className="newAppBar" elevation={0}>
                    <Toolbar className="newToolBar">
                        <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                        <Box className="title"><span>Risk Posture Audits</span></Box>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={2} my={8} className="auditBody" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={10}>{renderPreviousReports(allReports)}</Grid>
                    {renderReport(allReports[selectedReport], allReports.length - selectedReport)}

                </Grid>
            </div>)
    }

}

export default AssessmentScreen;